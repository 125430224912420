@import './Variables';

.header {
  height: $header_height;
  display: flex;
  align-items: center;
  padding: 0 $large_spacing;
  user-select: none;

  .hamburgerMenuIcon {
    width: 0;
    overflow: hidden;
    color: $darkgray;
    cursor: pointer;
    transition: color $short_duration, width $short_duration, margin $short_duration;

    &:hover {
      color: $black;
    }

    svg {
      width: 24px;
      vertical-align: top;
    }
  }

  .logoContainer {
    width: 180px;
    margin-top: 12px;
  }

  .navigation {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a.navigationLink {
      margin: 0 $medium_spacing;
      color: $darkgray;
      font-weight: normal;
      opacity: 0.8;
      transition: opacity $short_duration;
      &:last-child { margin-right: 0; }

      &.active {
        opacity: 1;
        font-weight: bold;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .closeBurger {
    position: fixed;
    top: $header_height;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: $high_z_index;
    background: transparent;
    transition: background $short_duration;

    &.open {
      pointer-events: fill;
      background: transparentize($black, 0.6);
    }
  }

  .hamburgerMenu {
    width: 240px;
    background: $white;
    position: fixed;
    top: $header_height;
    bottom: 0;
    left: -240px;
    padding: $large_spacing;
    z-index: $high_z_index + 1;
    transition: left $short_duration ease-in-out;

    &.open {
      left: 0;
    }

    .navigation {
      flex-direction: column;
      display: block;

      .navigationLink {
        display: block;
        margin: $large_spacing 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  @include responsive('<', 640px) {
    .hamburgerMenuIcon {
      width: 24px;
      margin-right: $large_spacing;
    }

    .navigation { display: none; }
    .hamburgerMenu .navigation { display: block; }
  }
}
