@import './Variables';

.hire {
  background: $purple;
  padding: $large_spacing;
  transition: padding 0.2s;

  .resume {
    background: $white;
    box-shadow: $standard_box_shadow;
    max-width: 1000px;
    margin: auto;
    padding: $xlarge_spacing;
    border-radius: $standard_border_radius;
    transition: padding 0.2s, border-radius 0.2s, box-shadow 0.2s;

    .resumeHeader {
      display: flex;

      .portrait {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: $purple;
        padding: 4px;
        margin-right: $large_spacing;
        overflow: hidden;
        transition: width 0.2s, padding 0.2s, margin 0.2s;

        img {
          width: 100%;
          border-radius: 50%;
          vertical-align: top;
        }
      }

      .me {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          font-size: 2.4rem;
          line-height: 1em;
          font-weight: bold;
          color: $purple;
        }
      }

      .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .contactMethod {
          display: flex;
          align-items: center;
          margin-bottom: $small_spacing;
          height: 18px;

          .contactIcon {
            margin-right: $small_spacing;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              fill: $purple;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  .section {
    .sectionHeader {
      display: flex;
      align-items: center;
      height: 18px;
      font-weight: bold;
      margin-top: $xlarge_spacing;
      margin-bottom: $medium_spacing;

      .sectionIcon {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: $purple;
          width: 18px;
          height: 18px;
        }
      }

      .sectionTitle {
        line-height: 18px;
        text-transform: uppercase;
        margin: 0 $small_spacing;
      }

      .sectionDivider {
        height: 1px;
        background: $black;
        flex: 1;
        opacity: 0.4;
      }
    }

    .sectionContent {
      .entry {
        &:not(:first-child) {
          margin-top: $large_spacing;
        }

        .entryTop {
          display: flex;

          .entryTitle {
            flex: 1;

            .titleBold {
              font-weight: bold;
              color: $purple;
            }
          }
        }

        .entryDescription {
          opacity: 0.6;
        }
      }
    }
  }

  @include responsive('<', 640px) {
    padding: 0;

    .resume {
      border-radius: 0;
      padding: $small_spacing;
      box-shadow: none;

      .resumeHeader .portrait {
        width: 0;
        padding: 0;
        margin: 0;
      }

      .section .sectionContent .entry .entryTop .location {
        display: none;
      }
    }
  }
}
