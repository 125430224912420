@import './Variables';

.home {
  .hero {
    height: 100vh;
    max-height: 800px;
    background: linear-gradient(to top right, #90ACFF, #CFA0FF);
    position: relative;
    overflow: hidden;
    user-select: none;

    .heroImageContainer {
      @include cover();
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        min-width: 105%;
        width: 105%;
        height: 105%;
        object-fit: cover;
        pointer-events: none;
      }
    }

    .heroTextContainer {
      @include cover();
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: $black;
      opacity: 0.7;

      .heroTextTitle {
        font-size: 3.4rem;
        line-height: 1em;
      }

      .heroTextSubtitle {
        font-size: 1.2rem;
      }
    }

    @include responsive('<', 800px) {
        max-height: 600px;

        .heroTextContainer {
          .heroTextTitle {
            font-size: 2.3rem;
          }

          .heroTextSubtitle {
            font-size: 0.8rem;
          }
        }
    }

    @include responsive('<', 600px) {
      max-height: 400px;
    }

    @include responsive('<', 500px) {
      max-height: 320px;

      .heroTextContainer {
        .heroTextTitle {
          font-size: 1.8rem;
        }

        .heroTextSubtitle {
          font-size: 0.7rem;
        }
      }
    }
  }

  .section {
    padding: $xlarge_spacing * 2 $xlarge_spacing;
    position: relative;

    .sectionHeader {
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
      line-height: 1em;
    }

    .flexContainer {
      max-width: 1200px;
      margin: auto;
      display: flex;
      align-items: center;

      .flexImage {
        max-width: 400px;
        border-radius: 50%;
        background: $purple;
        padding: $medium_spacing;

        img {
          width: 100%;
          border-radius: 50%;
          vertical-align: top;
        }
      }

      .flexText {
        font-size: 1.2rem;
        padding-left: $xlarge_spacing;
        min-width: 0;
        flex: 1;

        .highlight {
          color: $purple;
          font-weight: bold;
        }

        .text {
          margin-top: $large_spacing;
        }
      }

      @include responsive('<', 1000px) {
        .flexImage {
          max-width: 320px;
        }

        .flexText {
          font-size: 1rem;
        }
      }

      @include responsive('<', 800px) {
        flex-direction: column;

        .flexText {
          padding-left: 0;
          margin-top: $xlarge_spacing;
        }
      }
    }

    &.contact {
      background: radial-gradient(ellipse at bottom, #1B2735 0%, $black 100%);
      color: $white;
      overflow: hidden;

      @keyframes float {
        from { transform: translateY(0px); }
        to { transform: translateY(-2000px); }
      }

      @mixin stars($number, $size, $speed) {
        $box-shadow: random($number) + px random($number) + px $white;
        @for $i from 2 through $number { $box-shadow: $box-shadow, random(2000) + px random(2000) + px $white; }

        background: transparent;
        width: $size;
        height: $size;
        box-shadow: $box-shadow;
        animation: float $speed linear infinite;

        &:after {
            content: ' ';
            position: absolute;
            top: 2000px;
            background: transparent;
            width: $size;
            height: $size;
            box-shadow: $box-shadow;
        }
      }

      .stars1 { @include stars(700, 1px, 50s); }
      .stars2 { @include stars(200, 2px, 100s); }
      .stars3 { @include stars(100, 3px, 150s); }

      .socials {
        display: flex;
        justify-content: center;
        margin-top: $large_spacing;

        .social {
          width: 60px;
          height: 60px;
          margin: $large_spacing;
          color: $white;
          transition: color $short_duration;

          svg {
            transition: transform $short_duration;
          }

          &:hover {
            color: $purple;

            svg {
              transform: scale(1.1);
            }
          }
        }

        @include responsive('<', 500px) {
          flex-wrap: wrap;
          max-width: 320px;
          margin: $large_spacing auto 0 auto;
        }
      }
    }

    @include responsive('<', 500px) {
      padding: $xlarge_spacing $large_spacing;

      .hideSmall {
        display: none;
      }
    }
  }
}
