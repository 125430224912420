@import './Variables';

.portfolio {
  padding: $xlarge_spacing;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $black;
  transition: padding $short_duration;

  img {
    max-width: 1400px;
    width: 100%;
    box-shadow: $standard_box_shadow;
    transition: margin $short_duration;

    &:not(:first-child) {
      margin-top: $xlarge_spacing;
    }
  }

  @include responsive('<', 800px) {
    padding: $large_spacing;

    img:not(:first-child) {
      margin-top: $large_spacing;
    }
  }

  @include responsive('<', 600px) {
    padding: $medium_spacing;

    img:not(:first-child) {
      margin-top: $medium_spacing;
    }
  }
}
